<template>

  <div>
    <b-row>
      <b-col md="4">
        <b-card
          class=""
        >
          <!--Start background-->
          <b-form-group
            label="Фон"
            label-for="image"
          >
            <b-img
              :src="certificateData.image"
              class="w-25"
            />
            <b-form-file
              id="image"
              ref="refImageFileEl"
              v-model="certificateData.imageFile"
              browse-text="Обзор..."
              accept="application/image"
              placeholder="Выберите файл или перетащите его сюда..."
              drop-placeholder="Скиньте файл сюда..."
              @input="previewImage"
            />
          </b-form-group>
          <!--End background-->

          <!--Start title-->
          <b-form-group
            label="Текст заголовок"
            label-for="title"
          >
            <b-form-input
              id="title"
              v-model="certificateData.title"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="Позиция заголовок X"
            label-for="title_position_x"
          >
            <b-form-input
              id="title_position_x"
              v-model="certificateData.title_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="Позиция заголовок Y"
            label-for="title_position_y"
          >
            <b-form-input
              id="title_position_y"
              v-model="certificateData.title_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="Размер шрифт заголовок"
            label-for="title_font_size"
          >
            <b-form-input
              id="title_font_size"
              v-model="certificateData.title_font_size"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="Цвет шрифт заголовок"
            label-for="title_font_color"
          >
            <b-form-input
              id="title_font_color"
              v-model="certificateData.title_font_color"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <!--End title-->

          <!--Start body-->
          <b-form-group
            label="body"
            label-for="body"
          >
            <b-form-input
              id="body"
              v-model="certificateData.body"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="body_position_x"
            label-for="body_position_x"
          >
            <b-form-input
              id="body_position_x"
              v-model="certificateData.body_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="body_position_y"
            label-for="body_position_y"
          >
            <b-form-input
              id="body_position_y"
              v-model="certificateData.body_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="body_font_size"
            label-for="body_font_size"
          >
            <b-form-input
              id="body_font_size"
              v-model="certificateData.body_font_size"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="body_font_color"
            label-for="body_font_color"
          >
            <b-form-input
              id="body_font_color"
              v-model="certificateData.body_font_color"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="body_max_len"
            label-for="body_max_len"
          >
            <b-form-input
              id="body_max_len"
              v-model="certificateData.body_max_len"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <!--End body-->

          <!--Start profile-->
          <b-form-group
            label="profile"
            label-for="profile"
          >
            <b-form-input
              id="profile"
              v-model="certificateData.profile"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="profile_position_x"
            label-for="profile_position_x"
          >
            <b-form-input
              id="profile_position_x"
              v-model="certificateData.profile_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="profile_position_y"
            label-for="profile_position_y"
          >
            <b-form-input
              id="profile_position_y"
              v-model="certificateData.profile_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="profile_height"
            label-for="profile_height"
          >
            <b-form-input
              id="profile_height"
              v-model="certificateData.profile_height"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="profile_weight"
            label-for="profile_weight"
          >
            <b-form-input
              id="profile_weight"
              v-model="certificateData.profile_weight"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <!--End profile-->

          <!--Start name-->
          <b-form-group
            label="name"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="certificateData.name"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="name_position_x"
            label-for="name_position_x"
          >
            <b-form-input
              id="name_position_x"
              v-model="certificateData.name_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="name_position_y"
            label-for="name_position_y"
          >
            <b-form-input
              id="name_position_y"
              v-model="certificateData.name_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="name_font_size"
            label-for="name_font_size"
          >
            <b-form-input
              id="name_font_size"
              v-model="certificateData.name_font_size"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="name_font_color"
            label-for="name_font_color"
          >
            <b-form-input
              id="name_font_color"
              v-model="certificateData.name_font_color"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <!--End name-->

          <!--Start date-->
          <b-form-group
            label="date"
            label-for="date"
          >
            <b-form-input
              id="date"
              v-model="certificateData.date"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="date_position_x"
            label-for="date_position_x"
          >
            <b-form-input
              id="date_position_x"
              v-model="certificateData.date_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="date_position_y"
            label-for="date_position_y"
          >
            <b-form-input
              id="date_position_y"
              v-model="certificateData.date_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="date_font_size"
            label-for="date_font_size"
          >
            <b-form-input
              id="date_font_size"
              v-model="certificateData.date_font_size"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="date_font_color"
            label-for="date_font_color"
          >
            <b-form-input
              id="date_font_color"
              v-model="certificateData.date_font_color"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <!--End date-->

          <!--Start Signature-->
          <b-form-group
            label="signature_position_x"
            label-for="signature_position_x"
          >
            <b-form-input
              id="signature_position_x"
              v-model="certificateData.signature_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_position_y"
            label-for="signature_position_y"
          >
            <b-form-input
              id="signature_position_y"
              v-model="certificateData.signature_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_height"
            label-for="signature_height"
          >
            <b-form-input
              id="signature_height"
              v-model="certificateData.signature_height"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_weight"
            label-for="signature_weight"
          >
            <b-form-input
              id="signature_weight"
              v-model="certificateData.signature_weight"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text"
            label-for="signature_text"
          >
            <b-form-input
              id="signature_text"
              v-model="certificateData.signature_text"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text_position_x"
            label-for="signature_text_position_x"
          >
            <b-form-input
              id="signature_text_position_x"
              v-model="certificateData.signature_text_position_x"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text_position_y"
            label-for="signature_text_position_y"
          >
            <b-form-input
              id="signature_text_position_y"
              v-model="certificateData.signature_text_position_y"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text_font_family"
            label-for="signature_text_font_family"
          >
            <b-form-input
              id="signature_text_font_family"
              v-model="certificateData.signature_text_font_family"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text_font_size"
            label-for="signature_text_font_size"
          >
            <b-form-input
              id="signature_text_font_size"
              v-model="certificateData.signature_text_font_size"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="signature_text_font_color"
            label-for="signature_text_font_color"
          >
            <b-form-input
              id="signature_text_font_color"
              v-model="certificateData.signature_text_font_color"
              autofocus
              trim
              placeholder=""
              autocomplete="off"
              @change="previewImage"
            />
          </b-form-group>
          <b-form-group
            label="Подпись"
            label-for="signature"
          >
            <b-img
              :src="certificateData.signature"
              class="w-25"
            />
            <b-form-file
              id="signature"
              ref="refSignatureFileEl"
              v-model="certificateData.signatureFile"
              browse-text="Обзор..."
              accept="application/image"
              placeholder="Выберите файл или перетащите его сюда..."
              drop-placeholder="Скиньте файл сюда..."
              @input="previewImage"
            />
          </b-form-group>
          <!--End Signature-->

        </b-card>
      </b-col>
      <b-col md="8">
        <b-card
          class=""
        >
          <b-img
            :src="previewPhoto"
            class="w-100"
          />
        </b-card>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BImg, BFormGroup, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-cycle
import router from '@/router'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BImg,
    BFormGroup,
    BFormFile,

    vSelect,
  },
  data() {
    return {
      previewPhoto: null,
      certificateData: {
        image: null,
        imageFile: null,
        title: 'Title',
        title_position_x: '',
        title_position_y: '',
        title_font_family: 'segoeui',
        title_font_size: '',
        title_font_color: '#383cc1',
        body: 'За отличные и хорошие успехи в учебе, стремление учиться и познавать, прилежание, активную жизненную позицию.',
        body_position_x: '',
        body_position_y: '',
        body_font_family: 'segoeui',
        body_font_size: 30,
        body_font_color: '#383cc1',
        body_max_len: 80,
        profile: '',
        profile_position_x: '',
        profile_position_y: '',
        profile_height: 100,
        profile_weight: 100,
        name: '',
        name_position_x: '',
        name_position_y: '',
        name_font_family: 'segoeui',
        name_font_size: '',
        name_font_color: '#383cc1',
        date: '',
        date_position_x: '',
        date_position_y: '',
        date_font_family: 'segoeui',
        date_font_size: '',
        date_font_color: '#383cc1',
        date_format: '',
        signature: null,
        signatureFile: null,
        signature_position_x: -40,
        signature_position_y: '',
        signature_height: '',
        signature_weight: '',
        signature_text: 'Signature text',
        signature_text_position_x: '',
        signature_text_position_y: '',
        signature_text_font_family: 'segoeui',
        signature_text_font_size: '',
        signature_text_font_color: '#383cc1',
        for_quiz: '',
      },
    }
  },
  created() {
    this.view()
    this.previewImage()
  },
  methods: {
    getBase64Image(url, typeV = 'image') {
      const img = new Image()
      img.setAttribute('crossOrigin', 'anonymous')
      img.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = img.width
        canvas.height = img.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(img, 0, 0)
        const dataURL = canvas.toDataURL('image/png')
        if (typeV === 'image') {
          this.certificateData.image = dataURL
        } else if (typeV === 'signature') {
          this.certificateData.signature = dataURL
        }
        this.previewImage(false)
      }
      img.src = url
    },
    view() {
      // this.getBase64Image('https://edu.startupchoikhona.tj/backend/uploader/certificate/image/625fb99b99a56.png')
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/certificate/${this.certificateID}/view`, {})
        .then(response => {
          const { data } = response.data
          this.certificateData.title = data.title
          this.certificateData.title_position_x = data.title_position_x
          this.certificateData.title_position_y = data.title_position_y
          this.certificateData.title_font_family = data.title_font_family
          this.certificateData.title_font_size = data.title_font_size
          this.certificateData.title_font_color = data.title_font_color
          this.certificateData.body = data.body
          this.certificateData.body_position_x = data.body_position_x
          this.certificateData.body_position_y = data.body_position_y
          this.certificateData.body_font_family = data.body_font_family
          this.certificateData.body_font_size = data.body_font_size
          this.certificateData.body_font_color = data.body_font_color
          this.certificateData.body_max_len = data.body_max_len
          this.certificateData.profile = data.profile
          this.certificateData.profile_position_x = data.profile_position_x
          this.certificateData.profile_position_y = data.profile_position_y
          this.certificateData.profile_height = data.profile_height
          this.certificateData.profile_weight = data.profile_weight
          this.certificateData.name = data.name
          this.certificateData.name_position_x = data.name_position_x
          this.certificateData.name_position_y = data.name_position_y
          this.certificateData.name_font_family = data.name_font_family
          this.certificateData.name_font_size = data.name_font_size
          this.certificateData.name_font_color = data.name_font_color
          this.certificateData.date = data.date
          this.certificateData.date_position_x = data.date_position_x
          this.certificateData.date_position_y = data.date_position_y
          this.certificateData.date_font_family = data.date_font_family
          this.certificateData.date_font_size = data.date_font_size
          this.certificateData.date_font_color = data.date_font_color
          this.certificateData.date_format = data.date_format
          this.certificateData.signature_position_x = data.signature_position_x
          this.certificateData.signature_position_y = data.signature_position_y
          this.certificateData.signature_height = data.signature_height
          this.certificateData.signature_weight = data.signature_weight
          this.certificateData.signature_text = data.signature_text
          this.certificateData.signature_text_position_x = data.signature_text_position_x
          this.certificateData.signature_text_position_y = data.signature_text_position_y
          this.certificateData.signature_text_font_family = data.signature_text_font_family
          this.certificateData.signature_text_font_size = data.signature_text_font_size
          this.certificateData.signature_text_font_color = data.signature_text_font_color
          this.certificateData.for_quiz = data.for_quiz
          if (data.image) {
            this.getBase64Image(`https://edu.startupchoikhona.tj/backend/${data.image}`, 'image')
          }
          if (data.signature) {
            this.getBase64Image(`https://edu.startupchoikhona.tj/backend/${data.signature}`, 'signature')
          }
          this.previewImage(false)
        })
        .catch(() => {

        })
    },
    previewImage(s = true) {
      if (s) {
        const refImageFileEl = this.$refs.refImageFileEl && this.$refs.refImageFileEl.files.length > 0 ? this.$refs.refImageFileEl.files[0] : null
        const readerImageFile = new FileReader()
        if (refImageFileEl) {
          readerImageFile.readAsDataURL(refImageFileEl)
          readerImageFile.onload = () => {
            this.certificateData.image = readerImageFile.result
            this.previewImage(false)
          }
        }
      }

      if (s) {
        const refSignatureFileEl = this.$refs.refSignatureFileEl && this.$refs.refSignatureFileEl.files.length > 0 ? this.$refs.refSignatureFileEl.files[0] : null
        const readerSignatureFile = new FileReader()
        if (refSignatureFileEl) {
          readerSignatureFile.readAsDataURL(refSignatureFileEl)
          readerSignatureFile.onload = () => {
            this.certificateData.signature = readerSignatureFile.result
            this.previewImage(false)
          }
        }
      }
      const formData = new FormData()

      this.buildFormData(formData, this.certificateData)
      const config = {
        // example url
        url: 'https://edu.startupchoikhona.tj/backend/api/certificate/preview',
        method: 'POST',
        responseType: 'blob',
        data: this.certificateData,
      }
      this.$http(config)
        .then(response => {
          const reader = new FileReader()
          reader.readAsDataURL(response.data)
          reader.onload = () => {
            this.previewPhoto = reader.result
          }
        })
    },
  },
  setup() {
    const certificateID = router.currentRoute.params.id
    const buildFormData = (formData, data, parentKey) => {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data)
          .forEach(key => {
            if (data[key] && key !== 'imageFile' && key !== 'signatureFile') {
              buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key)
            }
          })
      } else {
        const value = data == null ? '' : data

        formData.append(parentKey, value)
      }
    }
    return {
      certificateID,
      buildFormData,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
